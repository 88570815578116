/* src/styles/Navbar.css */


.sum-nav {
  height: 80px;
  /* background-color: #948f8f; */
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
  list-style-type: none;
  margin-bottom: 20px;
  /* margin: 0; */
  padding: 20;
  max-width: 800px;
  margin: auto;
  /* color: #fff; */
}

.nav-item {
  list-style: none;
  font-size: 24px;
  padding: .5rem 1rem;
  /* background-color: #5D5DFF; */
  cursor: pointer; 
  text-decoration: none;
  /* font-weight: bold; */
  /* Add cursor to indicate clickable items */
}

.nav-item.active {
  /* color: #fff; */
  background-color: #5D5DFF; /* Change to desired active color */
}

.nav-item a {
  /* color: rgb(8, 2, 2); */
  color: #000;
  text-decoration: none;
}

.nav-item.active a {
  /* color: rgb(8, 2, 2); */
  color: #fff;
  text-decoration: none;
}

/* .nav-item:active {
  background-color: #0033ff;
} */