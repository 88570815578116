.input-container{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
    /* display: flex; */
}

.output-container{
    display: flex;
    justify-content: space-between;
    /* background: #fff; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 40px;
    height: 300px;
    /* display: flex; */
}


.statistic {
    /* background-color: red; */
    background: #fff;
    width: 390px;
    /* height: 668px; */
    border: 1px solid rgba(46, 131, 251, .2);
    
}

.summary {
    /* background-color: green; */
    background: #fff;
    width: calc(100% - 420px);
    /* height: 668px; */
    border: 1px solid rgba(46, 131, 251, .2);

    .tabs {
        display: flex;
        gap: 20px
    }
}

textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    box-sizing: border-box;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

.summary-output {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
    min-height: 100px;
}


/* .sum-button {
    background: #2e83fb;
    color: #fff;
    border: none;
    border-radius: 8px!important;
    font-size: 14px;
    font-weight: 400;
    line-height: 0!important;
    height: 42px;
    padding: 10px;
    width: 100%;
    margin-top: 10px!important;
} */


.sum-button {
    background: #2e83fb;
    color: #fff;
    border: none;
    border-radius: 8px!important;
    font-size: 14px;
    font-weight: 400;
    line-height: 0!important;
    height: 42px;
    padding: 10px;
    width: 100%;
    margin-top: 10px!important;
    position: relative; /* Ensure the spinner is positioned relative to the button */
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #fff; /* White */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
    margin-right: 10px; /* Space between spinner and text */
}

/* Keyframes for spinner */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Error message styles */
.error-message {
    color: #e74c3c; /* Red color for error message */
    font-size: 14px;
    margin-top: 10px;
    /* font-weight: 500px; */
}

.tabs {
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: space-around; /* Center align the tabs */
    margin-bottom: 10px; /* Space between tabs and summary */
    margin-top: 10px;
}

/* Tab button styles */
.tab-button {

    height: 40px;
    color: #000;
    background-color: #FAFAFA; 
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    height: 42px;
    padding: 0 15px;
    margin: 0 5px; 
    cursor: pointer;
}

.tab-button:hover {
    background-color: #fff; /* Darker shade on hover */
}