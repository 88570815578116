.url-sum-area {
    /* box-shadow: 0 7px 14px 0 rgba(52,58,64,.06) !important; */
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: #fff;
    max-width: 800px;
    height: 200px;
    padding: 20px;
    margin: 0 auto;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center;  */
}

.url-sum {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    width: 100%;

    align-items: center;
    height: 40px;
    /* gap: 20px; */
    /* padding: 0px; */
    /* flex-shrink: 0; */
}

.input-text {
    font-size: 14px;
    /* background-color: red; */
    width: 70%;
    /* max-width: 900px;  */
    padding: 0.5rem;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin-right: 20px;
    /* flex: 1; */
}

select {
    padding: 0.5rem;
}

/* .sum-button {
    border-radius: .375rem;
    color: #fff;
    background-color: #05f;
    border-color: #05f;
    border: 1px solid transparent;
    padding: .5625rem 1rem;
    cursor: pointer; /* Add pointer cursor for button 
} */


.input-container{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    /* max-width: 1140px; */
    /* width: 100%; */
    margin: auto;
    margin-bottom: 40px;
    /* display: flex; */
}

.url-sum-button {
    background: #2e83fb;
    color: #fff;
    border: none;
    border-radius: 8px!important;
    font-size: 14px;
    font-weight: 400;
    line-height: 0!important;
    height: 42px;
    padding: 10px;
    width: 30%;
    margin-top: 10px!important;
}

.output-container{
    display: flex;
    justify-content: space-between;
    /* background: #fff; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 40px;
    height: 300px;
    /* display: flex; */
}


.statistic {
    /* background-color: red; */
    width: 390px;
    /* height: 668px; */
}

.summary {
    /* background-color: green; */
    width: calc(100% - 420px);
    /* height: 668px; */
}