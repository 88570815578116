.row-title {
    /* display: flex; */
    max-width: 1140px;
    margin: 0 auto;
}

.row-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    grid-gap: 20px; /* Space between items */
    flex-wrap: wrap;
    max-width: 800px; /* Constrain width */
    margin: 0 auto; /* Center the grid container */
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 40px;
    /* padding: 20px; */
    padding: 2rem;
    background-color: #fff;
    text-align: center;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  .card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .card h3 {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .card p {
    font-size: 1rem;
    color: #666;
  }
  